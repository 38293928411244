export const typography = {
  headings: {
    lineHeight: '1.2em',
    fontWeight: '900',
    fontFamily: '"FuturaBold", Arial, Helvetica, sans-serif',
    letterSpacing: '.5px'
  },
  body: {
    fontFamily: 'FuturaRegular, "Trebuchet MS", Arial, sans-serif',
    lineHeight: '1.4em',
    letterSpacing: '.5px'
  },
  sizes: {
    xsmall: '0.6rem',
    msmall: '0.8rem',
    small: '0.9rem',
    large: '20px',
    larger: '24px',
    xlarge: '34px',
    trendtitle: '34px',
    compareLinkFont: '42px',
    xxlarge: '4rem'
  },
  baseFontSize: '16px'
}
export default typography
