import colors from '../colors'

export const articleItem = {
  button: {
    color: colors.white,
    backgroundColor: colors.black,
    borderColor: colors.black,
    hover: {
      color: colors.white,
      backgroundColor: colors.secondary,
      borderColor: colors.secondary
    }
  },
  itemMeta: {
    price: {
      weight: 'bold'
    }
  },
  headings: {
    color: colors.black
  }
}

export default articleItem
