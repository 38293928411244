import { PAGE_CONTEXT as p } from 'Constants'
import base from './base'
import hammerson from './hammerson'
import merge from 'lodash/merge'
import navPanelCategoryData from './navPanelCategoryData/hammersonData'

export default merge(base, hammerson, {
  name: 'hc',
  theme: 'v1',
  group: 'hammerson',
  apiKey: '8MhjSYwEk85pevH4ZgtjsVUJWmhW2yNX4TTjv45r8Bw7kdpk',
  siteVerifyCode: 'CL5le1B17J4oR_rc6thUCbgK5dATDNVQLCDCqfPFxlI',
  articlesOnProductListPage: true,
  categoryTitle: 'Shop by category',
  siteWide: {
    useExtraLinks: false,
    useExtraLinksDivider: true
  },
  hero: {
    title: 'Gently - Men’s Fashion, Style, Grooming',
    intro:
      "Live gently with our editors’ guide to the best in men’s fashion, grooming, lifestyle, home and wellness."
  },
  helmet: {
    metaTitleFormat: {
      [p.ARTICLE_LIST]:
        'All the latest fashion features, beauty inspiration and lifestyle updates. | {{PARTNER_NAME}}'
    },
    partner: 'Gently - Men’s Fashion, Style, Grooming',
    articles: {
      title: 'Latest shopping articles - Gently UK',
      description: `Discover the trending pieces that get the Gently editors seal of approval`
    },
    defaultDescription:
      'Gently is the premier digital publication for the modern, stylish man. Discover everything you need to ‘live gently’ with a curated selection of fashion, grooming, and lifestyle finds at every price point.'
  },
  routes: {
    [p.HUB]: {
      page: 'HubPage',
      path: `${process.env.ANT_PREFIX}/hub/:uuid/:slug`
    }
  },
  home: {
    articlesLinkEnabled: false,
    stores: [
      {
        name: 'All Saints',
        to:
          'https://www.awin1.com/cread.php?awinaffid=158752&awinmid=3708&clickref=homepagekjnlgo&platform=dl&p=%5B%5Bhttps%253A%252F%252Fwww.allsaints.com%252Fmen%252F%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh//hammerson/brands-we-love/tr:w-200/allsaints.png'
      },
      {
        name: 'Topshop',
        to:
          'https://www.awin1.com/cread.php?awinmid=6009&awinaffid=158752&clickref=homepagekjnlgo&p=%5B%5Bhttps%253A%252F%252Fwww.topshop.com%252F%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/topshop.png'
      },
      {
        name: 'Urban Outfitters',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=43613&u1=homepagekjnlgo&murl=https%3A%2F%2Fwww.urbanoutfitters.com%2Fen-gb%2F',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/urban-outfitters.png'
      },
      {
        name: 'Schuh',
        to:
          'https://www.awin1.com/cread.php?awinmid=2044&awinaffid=158752&clickref=homepagekjnlgo&p=%5B%5Bhttps%253A%252F%252Fwww.schuh.co.uk%252F%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/schuh.png'
      }
    ]
  },
  navPanelCategoryData,
  storesBlacklist: [
  ]
})
