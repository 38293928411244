export const mode = 'light'

const white = '#FFF'
const black = '#262A30'
const lightGrey = '#F4F6F8'

export const colors = {
  bodyBackground: mode === 'light' ? white : black,
  mainContainerBackground: mode === 'light' ? lightGrey : black,
  body: mode === 'light' ? black : white,
  primary: mode === 'light' ? black : white,
  active: mode === 'light' ? black : white,
  secondary: '#787878',
  tertiary: '#fafafa',
  discount: '#CCC',
  alt1: black,
  searchBar: '#4A4A4A',
  white: white,
  black: black,
  lightGrey: lightGrey,
  borderGrey: '#979797',
  mobileBorderLine: '#DADADA',
  borderGreyLight: '#D8D8D8',
  mainNavArticles: '#F4F4F2',
  darkGrey: '#4A4A4A',
  purple: black,
  lightActive: 'rgba(0, 0, 0, 0.41)',
  darkActive: 'rgba(0, 0, 0, 0.75)',
  overlayBackground: 'rgba(0, 0, 0, 0.5)',
  complementary: {
    active: white,
    primary: mode === 'light' ? white : black,
    secondary: white,
    alt1: '#205978'
  },
  buttonHover: mode === 'light' ? '#3C4046' : lightGrey,
  hoverTint: 'rgba(0,0,0,0.3)',
  boxShadow: 'rgba(0,0,0,0.1)'
}

export default colors
