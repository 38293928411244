import productCard from './productCard'
import articleCard from './articleCard'
import contentCard from './contentCard'
import articleProductCard from './articleProductCard'
import articleItem from './articleItem'
import icon from './icon'
import productOverview from './productOverview'
import articleTarget from './articleTarget'
import navPanel from './navPanel'
import contentListItem from './contentListItem'
import mainMenu from './mainMenu'

export default {
  articleItem,
  articleTarget,
  productCard,
  contentCard,
  articleCard,
  articleProductCard,
  icon,
  productOverview,
  navPanel,
  contentListItem,
  mainMenu
}
