import { mediumUp, largeUp } from 'Utils'
import { mode } from './colors'

const loadingBgc = mode === 'dark' ? '#636D7D' : '#FFF'

export const filterPanelHeader = (theme = {}) => `
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.complementary.primary};
`

export const defaultButton = (theme = {}) => `
  font-family: ${theme.typography.headings.fontFamily};
  font-weight: bold;
  display: inline-block;
  padding: 12px 35px;
  vertical-align: middle;
  text-align: center;
  line-height: 1em;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.complementary.primary};
  border: 1px solid ${theme.colors.primary};
  transition: background-color 100ms ease-in, color 100ms ease-in;
  @media ${mediumUp} {
    &:hover {
      cursor: pointer;
      text-decoration: none;
      border-color: ${theme.colors.buttonHover};
      background-color: ${theme.colors.buttonHover};
    }
  }
`

export const seeFilteredButton = (theme = {}) => `
  margin: ${theme.spacing.vertical.medium} auto 0 auto;
  display: block;
  color: ${theme.colors.primary};
  background-color: ${theme.colors.complementary.primary};
  font-family: Arial;
  font-size: ${theme.typography.sizes.small};
  line-height: 26px;
  border: 1px solid ${theme.colors.primary};
  @media ${mediumUp} {
    &:hover {
      background-color: ${theme.colors.purple};
      border: 1px solid ${theme.colors.purple};
      color: white;
    }
  }
  &:disabled {
    border: 1px solid ${theme.colors.discount};
    color: ${theme.colors.discount};
    cursor: default;
  }
`

const defaultVerticalSpacing = (theme = {}) => `
  margin-bottom: ${theme.spacing.vertical.small};
  @media ${mediumUp} {
    margin-bottom: ${theme.spacing.vertical.medium};
  }
`
const subText = (theme = {}) => `
  font-size: ${theme.typography.sizes.small};
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: ${theme.typography.headings.letterSpacing};
  color: ${theme.colors.borderGrey};
`

const sectionHeading = (theme = {}) => `
  text-align: center;
  letter-spacing: ${theme.typography.headings.letterSpacing};
  line-height: 1.2em;
  font-weight: ${theme.typography.headings.fontWeight};  
  font-size: ${theme.typography.sizes.larger};
  @media ${mediumUp} {
    font-size: ${theme.typography.sizes.xlarge};
  }
`

const mainContainerMaxWidth = (theme = {}) => `
  width: 96%;
  max-width: ${theme.layout.maxWidth.mainContainer}px;
  margin: 0 auto;
`

const mainContainerTopPadding = (theme = {}) => `
  padding-top: ${theme.spacing.vertical.small};
  @media ${largeUp} {
    padding-top: ${theme.spacing.vertical.medium};
  }
`

export const loadingLight = (theme = {}) => `
  width: 100%;
  background: linear-gradient(270deg, ${loadingBgc}, ${loadingBgc}, ${theme.colors.mainNavArticles}, ${loadingBgc}, ${loadingBgc}, ${loadingBgc});
  background-size: 1200% 1200%;
  -webkit-animation: Loading 6s ease infinite;
  -moz-animation: Loading 6s ease infinite;
  animation: Loading 6s ease infinite;

  @-webkit-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes Loading {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
`

export default {
  filterPanelHeader,
  defaultButton,
  defaultVerticalSpacing,
  subText,
  sectionHeading,
  mainContainerMaxWidth,
  mainContainerTopPadding,
  seeFilteredButton,
  loadingLight
}
