export const spacing = {
  vertical: {
    xxsmall: '8px',
    xsmall: '10px',
    small: '20px',
    medium: '30px',
    large: '40px'
  }
}
export default spacing
