import { spacing } from '../spacing'
import { typography } from '../typography'

export const articleTarget = {
  spacing: {
    small: spacing.vertical.small,
    large: spacing.vertical.small,
    paragraph: typography.xsmall
  }
}

export default articleTarget
