import { colors } from '../colors'
import { typography } from '../typography'

export const icon = {
  fill: colors.primary,
  height: '16px',
  width: '16px',
  size: {
    small: typography.sizes.xsmall
  }
}
export default icon
