import base from '../base'
import merge from 'lodash/merge'
import colors, { mode } from './colors'
import typography from './typography'
import layout from './layout'
import components from './components'
import mixins from './mixins'
import spacing from './spacing'
import zIndex from './zIndex'
import reset from './reset'

const theme = merge(base, {
  reset,
  components,
  colors,
  typography,
  layout,
  mixins,
  spacing,
  zIndex,
  mode
})

export default theme
