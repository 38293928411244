import { typography } from '../typography'
import { colors } from '../colors'
import { layout } from '../layout'
import { spacing } from '../spacing'

export const productOverview = {
  mobileTextAlign: 'center',
  overviewPadding: {
    large: '16px 0'
  },
  maxWidth: layout.maxWidth.productOverview,
  image: {
    justify: 'center'
  },
  brand: {
    color: colors.primary,
    spacing: spacing.vertical.large,
    textTransform: 'uppercase',
    letterSpacing: 'inherit',
    font: {
      family: typography.headings.fontFamily,
      weight: 'bold',
      size: typography.baseFontSize
    }
  },
  title: {
    maxWidth: 'inherit',
    color: colors.primary,
    spacing: typography.baseFontSize,
    textTransform: 'inherit',
    font: {
      family: typography.headings.fontFamily,
      weight: 'bold',
      size: {
        small: '22px',
        large: '22px'
      }
    }
  },
  price: {
    color: colors.primary,
    spacing: typography.baseFontSize,
    font: {
      family: typography.headings.fontFamily,
      size: '22px',
      weight: 'bold'
    },
    original: {
      font: {
        family: typography.headings.fontFamily,
        weight: 'bold',
        size: '22px',
        style: 'normal',
        textDecoration: 'line-through'
      }
    }
  },
  buyButton: {
    color: colors.complementary.primary,
    backgroundColor: colors.primary,
    textTransform: 'none',
    letterSpacing: 'inherit',
    spacing: typography.baseFontSize,
    padding: '16px 60px',
    border: {
      style: '1px solid',
      color: colors.purple,
      radius: 'initial'
    },
    font: {
      family: typography.headings.fontFamily,
      size: typography.baseFontSize,
      weight: 'bold'
    },
    hover: {
      color: colors.complementary.primary,
      backgroundColor: colors.borderGrey,
      borderColor: colors.borderGrey
    }
  },
  description: {
    maxWidth: 'none',
    color: colors.primary,
    spacing: '8px',
    label: {
      color: colors.primary,
      spacing: '8px',
      font: {
        family: typography.headings.fontFamily,
        size: typography.baseFontSize,
        weight: 'bold'
      }
    }
  }
}

export default productOverview
