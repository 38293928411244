import colors, { mode } from '../colors'

export const mainMenu = {
  color: colors.black,
  backgroundColor: colors.white,
  mobileHeading: {
    color: colors.white,
    backgroundColor: colors.black
  },
  active: '#ABABAB',
  current: 'white',
  complementary: {
    active: 'white',
    current: 'white'
  }
}

export default mainMenu
