export const layout = {
  maxWidth: {
    mainContainer: 1340,
    page: 1600,
    article: 800,
    productOverview: 800,
    trendImage: 1100
  },
  navPanel: {
    height: 443
  },
  gutterSize: {
    small: 10,
    medium: 20,
    large: 20,
    fallback: 20,
    article: { small: 10, large: 10, fallback: 10 },
    articleModal: { small: 20, large: 20, fallback: 20 }
  },
  articlePage: {
    gridGuide: {
      null: { small: 2, medium: 4, fallback: 4 },
      4: { small: 2, medium: 4, fallback: 4 },
      2: { small: 2, medium: 2, fallback: 2 },
      1: { small: 1, medium: 1, fallback: 1 },
      default: { small: 2, medium: 4, fallback: 4 }
    }
  },
  itemsPerRow: {
    productList: { small: 2, medium: 3, large: 4, fallback: 4 },
    altProduct: { small: 2, medium: 3, large: 4, fallback: 4 }
  }
}

export default layout
